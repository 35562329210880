import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";

import "./landing.css";




import banner1 from './images/banner1.png'; // Importing images from src folder
import banner2 from './images/banner2.png';
import banner3 from './images/banner3.png';
import banner4 from './images/banner4.png';

const images = [banner1, banner2, banner3, banner4];

const Imageslider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Automatically change the image every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <Box>
            <div className="slider-container">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`slider-image ${index === currentIndex ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${image})` }}
                    ></div>
                ))}
            </div>
        </Box >
    );
};

export default Imageslider;
