import { useEffect, useState } from "react";
import usePagination from "src/components/pagination/usePagination";
import axiosInstance from "src/utils/fetchUser";
import { incomeReportDefaultValues } from "src/pages/user/financial/ewallet/components/filterCard.jsx";

const useEwallet = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(incomeReportDefaultValues);
  const { count, onChange, page, seed, rowStart } = usePagination();
  const fetchData = async (page = 1, filter = {}) => {
    try {
      const { status, data } = await axiosInstance.get(`ewallet`, {
        params: {
          page,
          ...filter,
        },
      });
      const { data: report } = data;
      if (status) {
        const { last_page, data: list, from } = report;
        seed(last_page, from);
        setData(list);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData(page, filter);
  }, [page, filter]);

  return {
    data,
    fetchData,
    count,
    onChange,
    page,
    rowStart,
    setFilter,
  };
};

export default useEwallet;
