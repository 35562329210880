import React from "react";
import { Box } from "@mui/material";
import "./landing.css";
import Imageslider from "./imageslider";

import Logo from "./images/drives-logo.png";
import Aboutimg from "./images/aboutimg.jpg";

import X1R_Engine from "./images/X1R_Engine.webp";
import X1R from "./images/xir.webp";
import X1RPetrol from "./images/X1R_Petrol.webp";
import X1RDeisel from "./images/X1R_Diesel .webp";
import X1RAutomatic from "./images/X1R_Automatic.webp";
import X1RManual from "./images/X1R_Manual.webp";

import Product1 from "./images/product1.jpg";
import Product2 from "./images/product2.jpg";
import Product3 from "./images/product3.jpg";
import Product4 from "./images/product4.jpg";

import Post1 from "./images/post1.png";
import Post2 from "./images/post2.png";
import Post3 from "./images/post3.png";
import Post4 from "./images/post4.png";
import Post5 from "./images/post5.png";
import Post6 from "./images/post6.png";
import Videoss from "src/layouts/components/landing/images/profilevedio.mp4";

const Index = () => {
  return (
    <Box className="landingBody">
      <div className="primaryBg">
        <div className="landingContainer">
          <div className="navSection">
            <div className="logoSection">
              <img src={Logo} />
            </div>
            <div className="signupSection">
              <a href="/auth/login" className="login_button">
                Log in
              </a>
              <a href="/auth/register" className="signup_button">
                Sign up
              </a>
            </div>
          </div>
        </div>

        <div className="landingContainer">
          <div className="bannerSection flex items-center">
            <div className="w_50 pr_15 relative mt_15">
              <h1 className="bannerTitle roboto-condensed">
                Drives and Earn <span className="text_primary"> mission</span>{" "}
                is to get extra income for all of us!
              </h1>
              <p className="bannerPara">
                We can drive as long as our strength allowed us. When we are not
                feeling well then we cannot do what we are supposed to do to
                provide for the family! Let’s build a business together that we
                can even have a vacation and still earn for our family! “Lending
                a hand towards self-reliance and business profitability!”
              </p>

              <div className="clearboth"></div>
              <div className="w_full flex flex-wrap items-center mt_20">
                <a
                  href="/auth/register"
                  className="banner_button flex items-center mt_20"
                >
                  <span>Register Now </span>
                  <img
                    className="ml_10"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAANCAYAAABLjFUnAAAABHNCSVQICAgIfAhkiAAAAJFJREFUKFNjZCAB/P//PwGovB+IHRkZGS+ga2UkwSwGoGEGQPUHgPg/NgNJMgxkMT4DSTYMn4FkGYbLQEagsycAJfVJCTsktQpANgh/AGJDkGGgALUn0zBkbYaUeFMAaNJ+IAbFcCIwqSwgyzCgbzAMAjmRZMNwGUSyYfgMIsewBqCmelgYUZSdYOkLW74EyQEAzSFDWOVepfYAAAAASUVORK5CYII="
                  />
                </a>
              </div>
              <div className="clearboth mb_15"></div>
            </div>
            <div className="w_50 bannerRight relative">
              <Imageslider />
            </div>
          </div>
        </div>
      </div>

      <div className="landingContainer section">
        <div className="flex flex-wrap mb_20 flex_col_reverse">
          <div className="w_videoLeft">
            <div className="smalltitle lato-regular">Vision </div>
            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              To be the leading network marketing company in Africa that deals
              with automotive products and wellness of the drivers!
            </div>
            <div className="smalltitle lato-regular mt_20">
              Company Profile{" "}
            </div>

            <p className="mt_10 mb_20 lato-regular">
              Drives and Earn (DaE) was organized and managed by
              transformation-minded industrialists, entrepreneurs and
              professionals, offering proven expertise, and wealth of experience
              to catalyze a society, where people are enriched and lives are
              improved.
            </p>
          </div>
          <div className="w_videoRight relative">
            <video className="videoSize" controls>
              <source src={Videoss} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="w_full mt_20 relative ">
          <img src={Aboutimg} />
          <ul className="postDesigns">
            <li>
              <img src={Post1} />
            </li>
            <li>
              <img src={Post2} />
            </li>
            <li>
              <img src={Post3} />
            </li>
            <li>
              <img src={Post4} />
            </li>
            <li>
              <img src={Post5} />
            </li>
            <li>
              <img src={Post6} />
            </li>
          </ul>
        </div>
      </div>

      <div className="landingContainer section">
        <div className="flex flex-wrap items-center flex_col_reverse mt_50 prBox">
          <div className="w_70  mb_20 mt_20 pr_30">
            <div className="smalltitle lato-regular">Increases engine life</div>
            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              X1R Engine Treatment
            </div>
            <p className="mt_20 mb_20 lato-regular">
              On April 2000, The X-1R Corporation, received the prestigious
              honor of being the only lubricant company in the world ever
              inducted into The Space Foundation/NASA Space Technology Hall Of
              Fame. X-1R is the only lubricants company worldwide whose products
              are designated as Certified Space Technology.
            </p>
            <p className="mt_10 mb_20 lato-regular">
              Discover what NASA already know and find out why winning racing
              drivers around the world have switched to X-1R’s proven
              technology. Across the USA millions of drivers have already
              discovered the benefits of using X-1R and that’s because we
              deliver a full range of performance enhancing lubricants that keep
              engines running smoother and longer. Use X-1R just once and you
              will quickly notice an increase in engine power (Elliot Bros
              Racing Team) and experience improved fuel economy (ASME verified)
              whilst reducing wear significantly (Oakland U.)
            </p>
          </div>
          <div className="w_30  mb_20 mt_20 pl_30">
            <div className="prImgHolder">
              <img src={X1R_Engine} className="relative" />
            </div>
          </div>
        </div>

        {/* ********************************************* */}

        <div class="clearboth w-full mt_50 mb_20"></div>
        <div className="flex flex-wrap items-center mt_20 prBox">
          <div className="w_30  mb_20 mt_20 pr_30">
            <div className="prImgHolder">
              <img src={X1R} className="relative" />
            </div>
          </div>
          <div className="w_70  mb_20 mt_20 pl_30">
            <div className="smalltitle lato-regular">Safe and easy to use</div>

            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              X1R Engine Flush
            </div>
            <p className="mt_20 mb_20 lato-regular">
              Over the years as your vehicle accumulates miles it is also
              accumulating harmful deposits that will eventually cost you money.
              Even though you routinely service your vehicle sludge, gum,
              varnish, grit and many other unwanted items will be in your
              engine. These deposits slowly clog oils passaged and restrict the
              oil flow particularly in the valve train area. Unwanted deposits
              in your engine lead to increased wear and tear and increased fuel
              consumption. These deposits will remain in your engine even after
              your normal oil change, gradually building and ultimately harming
              your engine. X-1R Engine Flush is specifically formulated with
              controlled-released detergents that clean your engine without the
              need for solvents. Regular use of X-1R Engine Flush will help keep
              your vehicle in tip-top condition.
            </p>
          </div>
        </div>

        {/* ********************************************* */}

        <div class="clearboth w-full mt_20 mb_20"></div>
        <div className="flex flex-wrap items-center flex_col_reverse mt_50 prBox">
          <div className="w_70  mb_20 mt_20 pr_30">
            <div className="smalltitle lato-regular">
              The Complete Petrol System Treatment
            </div>

            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              X1R Petrol System Treatment
            </div>
            <p className="mt_20 mb_20 lato-regular">
              X-1R Petrol System Treatment contains Injector Cleaner, Power
              Booster, Valve Protector, Upper Cylinder Lubricant, Carbon
              Eliminator, Moisture Eliminator and Fuel Stabilizer which when
              combined with Strong Detergents and Deposits Control Agents will
              clean and keep clean the entire fuel delivery system and
              combustion chamber when used at regular intervals. With a clean
              fuel system you will benefit from a more efficient combustion
              process that will use less petrol to deliver more power. PST will
              not only clean but also protect the Fuel system.
            </p>
          </div>
          <div className="w_30  mb_20 mt_20 pl_30">
            <div className="prImgHolder">
              <img src={X1RPetrol} className="relative" />
            </div>
          </div>
        </div>

        {/* ********************************************* */}

        <div class="clearboth w-full mt_50 mb_20"></div>
        <div className="flex flex-wrap items-center mt_20 prBox">
          <div className="w_30  mb_20 mt_20 pr_30">
            <div className="prImgHolder">
              <img src={X1RDeisel} className="relative" />
            </div>
          </div>
          <div className="w_70  mb_20 mt_20 pl_30">
            <div className="smalltitle lato-regular">
              The Complete Diesel System Treatment
            </div>
            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              X1R Diesel System Treatment
            </div>
            <p className="mt_20 mb_20 lato-regular">
              X-1R Diesel System Treatment contains Injector Cleaner, Power
              Booster, Valve Protector, Upper Cylinder Lubricant, Carbon
              Eliminator, Moisture Eliminator and Fuel Stabilizer which when
              combined with Strong Detergents and Deposits Control Agents will
              clean and keep clean the entire fuel delivery system and
              combustion chamber when used at regular intervals. With a clean
              fuel system you will benefit from a more efficient combustion
              process that will use less petrol to deliver more power.
            </p>
          </div>
        </div>

        {/* ********************************************* */}
        <div class="clearboth w-full mt_50 mb_20"></div>
        <div className="flex flex-wrap items-center flex_col_reverse mt_20 prBox">
          <div className="w_70  mb_20 mt_20 pr_30">
            <div className="smalltitle lato-regular">
              Reduces operating temperature
            </div>
            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              X1R Automatic Transmission Treatment
            </div>
            <p className="mt_20 mb_20 lato-regular">
              Transmission fluids are subject to some of the highest temperature
              and pressure regimes in your car. Ordinary transmission fluids
              breakdown rapidly under these extreme conditions leading to
              foaming, when this occurs your transmission is no longer
              protected, the temperature rises and excessive wear and tear takes
              place. This is why you may experience irregular shifting and an
              increase in noise and even premature transmission failure. X-1R
              Automatic Transmission Treatment is specifically formulated to
              stop foaming and fluid oxidation leading to a smoother, quieter,
              cooler, longer lasting transmission.
            </p>
          </div>
          <div className="w_30  mb_20 mt_20 pl_30">
            <div className="prImgHolder">
              <img src={X1RAutomatic} className="relative" />
            </div>
          </div>
        </div>

        {/* ********************************************* */}

        <div class="clearboth w-full mt_50 mb_20"></div>
        <div className="flex flex-wrap items-center mt_20 prBox">
          <div className="w_30  mb_20 mt_20 pr_30">
            <div className="prImgHolder">
              <img src={X1RManual} className="relative" />
            </div>
          </div>
          <div className="w_70  mb_20 mt_20 pl_30">
            <div className="smalltitle lato-regular">
              Compatible with dexron and mercon type fluids
            </div>
            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase">
              X1R Manual Transmission Treatment
            </div>
            <p className="mt_20 mb_20 lato-regular">
              Transmission fluids are subject to some of the highest temperature
              and pressure regimes in your car. Ordinary transmission fluids
              breakdown rapidly under these extreme conditions leading to
              foaming, when this occurs your transmission is no longer
              protected, the temperature rises and excessive wear and tear takes
              place. This is why you may experience irregular shifting and an
              increase in noise and even premature transmission failure. X-1R
              Manual Transmission Treatment is specifically formulated to stop
              foaming and fluid oxidation leading to a smoother, quieter,
              cooler, longer lasting transmission.
            </p>
          </div>
        </div>

        {/* ********************************************* */}
      </div>

      <div className="landingContainer section">
        <div className="flex flex-wrap">
          <div className="relative">
            <div className="text-2xl mt_15 roboto-condensed font-800 mt_15 uppercase text-center">
              Other Products
            </div>

            <ul className="productList">
              <li>
                <div className="ImgBox">
                  <img src={Product1} />
                  <p>SAE Engine Oil(5W40, API-SM)</p>
                </div>
              </li>
              <li>
                <div className="ImgBox">
                  <img src={Product2} />
                  <p>Semi Synthetic Engine Oil (5W40-API SM)</p>
                </div>
              </li>
              <li>
                <div className="ImgBox">
                  <img src={Product3} />
                  <p>Premium Engine Oil (CH4-10W50)</p>
                </div>
              </li>
              <li>
                <div className="ImgBox">
                  <img src={Product4} />
                  <p>Premium Lubricants (CH4-10W50)</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="landingContainer">
          <div className="footerlogoSection">
            <img src={Logo} />
          </div>
          <div class="w_70 mx-auto mb_20 text-center">
            <div className="smalltitle lato-regular mt_20">Contact us</div>
            <p className="text_12">PO Box CO 2776 Community 1, Tema, Ghana</p>
            <p className="text_12 mt_5">WhatsApp: +233209366857 </p>
            <p className="text_12 mt_5">
              <a href="mailto:great8rsc@gmail.com"> great8rsc@gmail.com </a>{" "}
            </p>
          </div>

          <p className="w_70 mx-auto mt_10 text-center font_13">
            Copyright © 2024 DrivesAndEarn . All rights reserved.
          </p>
        </div>
      </div>
    </Box>
  );
};

export default Index;
